<template>
<Header />
  <div class="container">
    
    <div>
        <h2>Edit News</h2>
        <form @submit.prevent="update" class="text-justify">
            <div>
                <InputText class="p-mb-2 p-mt-2" type="text" placeholder="Title" v-model="form.title" required />
            </div>
            <div>
                <Editor v-model="form.content" editorStyle="height: 320px" placeholder="News content goes here" required/>
            </div>
            <Button label="Publish Updated News" type="submit" class="p-mt-2" />
            <router-link class="no-decor" style="text-decoration:none" :to="`/`">
              <Button label="Cancel" class="p-mt-2 p-ml-2" />
            </router-link>
        </form>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import {reactive, computed, onMounted} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {getNews, updateNews} from '@/fbDomain'
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Editor from 'primevue/editor';
import firebase from 'firebase'

export default {
  components: {Header, InputText, Button, Editor},
  setup() {
    const router = useRouter()
    const route = useRoute()
    const newsId = computed(() => route.params.id)

    const form = reactive({title: '', content:''})
    onMounted(async () => {
        const news = await getNews(newsId.value)
        form.title = news.title
        form.content = news.content
    })

    const update = async () => {
        await updateNews(newsId.value, { ...form, updatedBy:localStorage.getItem("ploUserName"), updatedByEmail:localStorage.getItem("ploUserEmail"), updatedAt: firebase.firestore.Timestamp.fromDate(new Date()) })
        router.push('/')
        form.title =''
        form.content =''
    }

    return {form, update}
  },
}
</script>

